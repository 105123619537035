// ouverture de l'overlay des cards
function parseCardSingleClickOverlay(){
    $('.js-listing-producteurs .card:not(.parsed), .card.card-events:not(.parsed)').on('click',function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href')+'?overlay';
        var overlay_id = 'overlay-single';

        $('#' + overlay_id + ' .js-overlay-content').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-overlay-content').html(responseHTML);
        });

        th_overlay.open(overlay_id, true, true, false);

    }).addClass('parsed');
}

parseCardSingleClickOverlay();