//spécificités listing Agenda
parseCardSingleClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topleft');
}


// PAGE LISTING SIT
function callbackMapPageListingLEI(macarte) {
    macarte.zoomControl.setPosition('topright');
}


// listing SIT classique - uniquement une map en overlay
var listingSitDefault = new thListingManager({
    selectors: {
        mapoverlay: '.js-listing-overlay-map',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        // $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
    }
});

// listing SIT classique - uniquement map en aside
var listingSitDefaultWithMap = new thListingManager({
    selectors: {
        listing: ".js-listing-default-map",
        mapoverlay: '.js-listing-overlay-map',
        map: '.js-listing-map',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    // update_form: true, // permet de mettre à jour les valeurs de formulaire en fonction du filtrage utilisateur
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        // $('.facette-radio, .facette-checkbox label, .facette-switch').append('<span></span>');
    }
});

// listing Producteurs - uniquement une map en overlay + overlay sur les cards
var listingProducteurs = new thListingManager({
    selectors: {
        listing: '.js-listing-producteurs',
        map: '.js-map-producteurs',
        btn_loc: '.js-listing-btn-geoloc',
    },
    mapOptions: {
        onlyDesktop: false,
        hasInfoWindow: false,
        hasOverlay: true,
        markerIcon: 'color-default',
        markerIconHover: 'color-hover',
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
        parseCardSingleClickOverlay();
        initSliders();
    }
});

// listing CPT - pas de map
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
    },
    afterUpdate: function () {
        // si composant favoris :
        // th_favoris.parseItems();
        // th_favoris.addEvents();
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
            if (listingSitDefaultWithMap.loaded) {
                listingSitDefaultWithMap.initMapOverlay();
            }
            if (listingSitDefault.loaded) {
                listingSitDefault.initMapOverlay();
            }
            if (listingProducteurs.loaded) {
                listingProducteurs.initMapOverlay();
            }
        }, 850);
    }
});

function initSlider(slider) {
    const splide = new Splide(slider, {
        breakpoints: {
            600: {
                arrows: false
            },
        }
    }).mount();
}

function initSliders() {
    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-overlay-map');
    sliders.forEach(function (slider) {
        initSlider(slider);
    });
}

/* Initialisation du slider de la carte */
(function () {
    setTimeout(function () {
        initSliders();
    }, 550);
})();
