function callbackMapProducteurs(macarte) {
    macarte.zoomControl.setPosition('topright');

    let iconOptions = {
        className: 'map-marker',
        html: "<div class='map-marker__pin'></div>",
        iconSize: [20, 20],
        iconAnchor: [10, 10]
    };

    th_maps.addMarkerIcon('color-default', iconOptions);
    th_maps.addMarkerIcon('color-hover', {
        ...iconOptions,
        className: iconOptions.className + ' map-marker--hover'
    });

    const colors = ['affinage', 'fermier', 'laitier'];
    colors.forEach((color) => {
        th_maps.addMarkerIcon('color-default-' + color, {
            ...iconOptions,
            className: iconOptions.className + ' --color-' + color
        });

        th_maps.addMarkerIcon('color-hover-' + color, {
            ...iconOptions,
            className: iconOptions.className + ' map-marker--hover' + ' --color-' + color
        });

    });
}
