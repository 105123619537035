(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            pagination: true,
            autoHeight: true,
            // Slider en mode loop et autoplay si besoin
            autoplay: true,
            interval: 2000,
            type: 'loop',
            pauseOnHover: false,

            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    });

})();