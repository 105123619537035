(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-image-texte');
    sliders.forEach(slider => {

        const splide = new Splide(slider, {
            // focus: 'center',
            gap: 0,
            pagination: true,
            cloneStatus: false,
            trimSpace:false,
            perMove: 1,
            drag: false,
            arrows: true,
        }).mount();
    });

})();