(function () {
    // Languages selector
    const langDropdownDesktop = document.querySelector('.js-lang-dropdown-desktop');
    if (langDropdownDesktop) {
        const trigger = langDropdownDesktop.querySelector('.lang-dropdown-desktop__btn');
        trigger.addEventListener('click', e => {
            e.preventDefault();
            langDropdownDesktop.classList.toggle('is-open');
        });
    }

    // Selectric est activé par défaut sur toutes les selects,
    // mais nous on ne veut pas l'avoir sur le sélecteur de langues en version tablette / mobile
    $('.js-lang-dropdown-mobile').selectric('destroy');

    // Select sur la version mobile
    const selectMobile = document.querySelector('.js-lang-dropdown-mobile');
    if (selectMobile) {
        selectMobile.addEventListener('change', (e) => {
            window.location.href = selectMobile.options[selectMobile.selectedIndex].value;
        });
    }
})();





