(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-relation');
    sliders.forEach(slider => {

        const splide = new Splide(slider, {
            gap: 35,

            breakpoints: {
                600: {
                    arrows: false,
                    gap: 10,
                },
            }
        }).mount();
    });

})();