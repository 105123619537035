(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-galerie');
    sliders.forEach(slider => {

        const splide = new Splide(slider, {
            gap: 30,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'-

            breakpoints: {
                600: {
                    arrows: false,
                    gap: 10,
                    direction: 'ltr',       // direction toujours de gauche à droite en mobile
                },
            }
        }).mount();
    });

})();